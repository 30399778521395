import React from "react";
import Loadable from "react-loadable";

const LoadableTableau = () => {
  const LoadableComponent = Loadable({
    loader: () => import("./TableauViz"),
    loading() {
      return (
        <div style={{ textAlign: "center" }}>
          <h4>Loading...</h4>
        </div>
      );
    },
  });

  return <LoadableComponent />;
};

export default LoadableTableau;
