import React from "react";

const BannerRegistration = props => (
  <section id="banner" className="major candles">
    <div className="inner">
      <header className="major">
        <h1>{props.title}</h1>
      </header>
      <div className="content">
        <p>{props.heading}</p>
        <br />
      </div>
    </div>
  </section>
);

export default BannerRegistration;
