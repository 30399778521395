import React from "react";

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://veym.net"
            target="_blank"
            rel="noopener noreferrer"
            className="icon alt fa-external-link"
          >
            <span className="label">VEYM.net</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/groups/2478429619104389/"
            target="_blank"
            rel="noopener noreferrer"
            className="icon alt fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>The Vietnamese Eucharistic Youth Movement in the U.S.A.</li>
        <li>Phong Trào Thiếu Nhi Thánh Thể Việt Nam tại Hoa Kỳ </li>
        <li>
          <a href="https://veym.net" target="_blank" rel="noopener noreferrer">
            veym.net
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
