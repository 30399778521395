import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <h1>NOT FOUND</h1>
          <p>The page you are looking for does not exist.</p>
          <br />
          <ul className="actions">
            <li>
              <Link to="/" className="button next">
                Go to Home
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
