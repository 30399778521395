import React from "react";
import { Link } from "gatsby";

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>{props.title}</h1>
      </header>
      <div className="content">
        <p>{props.heading}</p>
        <br />
      </div>
      <ul className="actions">
        <li>
          <Link to="/updates" className="button special">
            Latest Updates
          </Link>
        </li>
        {/* <li>
          <Link to="/registration" className="button special">
            Register
          </Link>
        </li> */}
      </ul>
    </div>
  </section>
);

export default Banner;
