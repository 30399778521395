/* eslint-disable */
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = props => (
  <header id="header" className="reveal">
    <Link to="/" className="logo">
      <strong>VDH7</strong>
      {/* <span>VDH7</span> */}
    </Link>
    <nav>
      <a className="menu-link" onClick={props.onToggleMenu}>
        Menu
      </a>
    </nav>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  onToggleMenu: PropTypes.func,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
