import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import Img from "gatsby-image";
import moment from "moment";
import SEO from "../components/seo";
import { getSlug } from "../utils/getSlug";

class HomeIndex extends React.Component {
  render() {
    const {
      data,
      data: {
        markdownRemark: {
          frontmatter: { title, heading, mainpitch },
        },
        officialLogo,
      },
    } = this.props;
    const formattedDescription = mainpitch.description
      .split(`\n\n`)
      .map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
      .join(``);

    const formattedSubtitle = mainpitch.subtitle2
      .split(`\n\n`)
      .map(paragraph => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
      .join(``);

    return (
      <Layout>
        <SEO title="Home" />

        <Banner title={title} heading={heading} />

        <div id="main">
          <section id="two">
            <div className="inner">
              {/* <header className="major">
                <h2>{data.markdownRemark.frontmatter.mainpitch.title}</h2>
                <h3>{data.markdownRemark.frontmatter.mainpitch.subtitle}</h3>
              </header> */}
              <div className="main">
                <div className="grid-wrapper">
                  <div className="col-4">
                    <h2>{mainpitch.title}</h2>
                    <h3>{mainpitch.subtitle}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: formattedSubtitle }}
                    ></div>
                    <br />
                  </div>
                  <div className="col-4">
                    <Img fluid={officialLogo.childImageSharp.fluid} />
                  </div>
                  <div className="col-4">
                    <div className="table-wrapper">
                      <table className="alt">
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td>{mainpitch.date}</td>
                          </tr>
                          <tr>
                            <td>Location</td>
                            <td>
                              {mainpitch.location}
                              <br />
                              {mainpitch.location2}
                            </td>
                          </tr>
                          <tr>
                            <td>Nearby Airport</td>
                            <td>{mainpitch.nearbyairport}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="inner">
                <a
                  href={mainpitch.speakerImage.childImageSharp.original.src}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    fluid={mainpitch.speakerImage.childImageSharp.fluid}
                    alt="Speakers"
                  />
                </a>
              </div>
              <br />
              <div className="inner">
                <section className="major">
                  <a
                    href={mainpitch.agendaImage.childImageSharp.original.src}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img
                      fluid={mainpitch.agendaImage.childImageSharp.fluid}
                      alt="Agenda"
                    />
                  </a>
                </section>
              </div>
              <br />
              <hr />
              <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
              <br />
            </div>
          </section>
          <div className="inner">
            <section className="major">
              <h2>Latest Updates</h2>
              <p>Keep up with the latest VDH7 updates below:</p>
              <ul className="actions">
                <li>
                  <Link to="/updates" className="button next">
                    See All Updates
                  </Link>
                </li>
              </ul>
            </section>
          </div>
          <section id="one" className="tiles">
            {data.allMarkdownRemark.edges.map(
              ({
                node: {
                  id,
                  frontmatter: { title, date, featuredimage },
                },
              }) => (
                <article
                  key={id}
                  style={{
                    backgroundImage: `url(${featuredimage.childImageSharp.original.src})`,
                  }}
                >
                  <header className="major">
                    <h3>{title}</h3>
                    <p>{moment(date).format("MMMM Do, YYYY")}</p>
                  </header>
                  <Link
                    to={`/${getSlug(title)}`}
                    className="link primary"
                  ></Link>
                </article>
              )
            )}
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 4
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { templateKey: { ne: "page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
                original {
                  src
                }
              }
            }
            date
          }
          html
          id
          excerpt
          rawMarkdownBody
        }
      }
    }
    officialLogo: file(relativePath: { eq: "OfficialLogo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 800) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    markdownRemark(frontmatter: { pageName: { eq: "homeindex" } }) {
      frontmatter {
        heading
        mainpitch {
          date
          description
          location
          location2
          nearbyairport
          subtitle
          subtitle2
          title
          speakerImage {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
          }
          agendaImage {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
          }
        }
        title
      }
    }
  }
`;
