import React from "react";
import { Link } from "gatsby";
// import danceimage from "../images/DHVDH6/Dance2.jpg";

const BannerUpdates = props => (
  <section
    id="banner"
    className="style2"
    // style={{ backgroundImage: `url(${danceimage})` }}
  >
    <div className="inner">
      <header className="major">
        <h1>{props.title}</h1>
      </header>
      <div className="content">
        <p>Keep up with the latest updates regarding VDH7.</p>
      </div>
      <ul className="actions">
        {/* <li>
            <a
              href="https://events.veym.net/e/8/ve-dat-hua-7"
              target="_blank"
              rel="noopener noreferrer"
              className="button next"
            >
              Register Now
            </a>
          </li> */}
        <Link to="/registration" className="button special disabled">
          Register
        </Link>
      </ul>
    </div>
  </section>
);

export default BannerUpdates;
