import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import Img from "gatsby-image";

const Sponsorship = props => {
  const data = useStaticQuery(graphql`
    query SponsorshipQuery {
      markdownRemark(frontmatter: { pageName: { eq: "sponsorship" } }) {
        frontmatter {
          heading
          buttontext
          registerlink
          title
          image1 {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
          }
        }
        html
      }
    }
  `);

  const {
    title,
    image1,
    image1: {
      childImageSharp: {
        original: { src: image1src },
      },
    },
    image2,
    image2: {
      childImageSharp: {
        original: { src: image2src },
      },
    },
    image3,
    image3: {
      childImageSharp: {
        original: { src: image3src },
      },
    },
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <SEO
        title="Sponsorship"
        description={`Fundraising and Sponsorship Opportunities`}
      />

      <div id="main" className="alt sponsorship">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>{title}</h1>
            </header>
            {/* <div className="table-wrapper">
              <ul className="actions">
                <li>
                  <a
                    href={data.markdownRemark.frontmatter.registerlink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button special big"
                  >
                    {data.markdownRemark.frontmatter.buttontext}
                  </a>
                </li>
              </ul>
            </div> */}
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
            <br />
            <hr />
            <br />
            See the following flyers for sponsorship and fundraising
            opportunities.
            <br />
            <br />
            <div className="flyers">
              {/* <div className="column">
                <a href={image1src} target="_blank" rel="noopener noreferrer">
                  <Img fluid={image1.childImageSharp.fluid} alt="Flyer 1" />
                </a>
              </div>
              <div className="column">
                <a href={image2src} target="_blank" rel="noopener noreferrer">
                  <Img fluid={image2.childImageSharp.fluid} alt="Flyer 2" />
                </a>
              </div> */}
              <div className="column">
                <a href={image3src} target="_blank" rel="noopener noreferrer">
                  <Img fluid={image3.childImageSharp.fluid} alt="Flyer 3" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Sponsorship;
