/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/updates">
            Updates
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/sponsorship">
            Sponsorship
          </Link>
        </li>
      </ul>
      <ul className="actions vertical">
        <li>
          <Link className="button special fit" to="/registration">
            Registration
          </Link>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu}>
      Close
    </a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
};

export default Menu;
