import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import moment from "moment";

const Post = ({
  data: {
    markdownRemark: {
      excerpt,
      frontmatter: { title, date },
      html,
    },
  },
}) => (
  <Layout>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={excerpt} />
    </Helmet>
    <div className="post">
      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <Link to="/">Home</Link>{" "}
            <span className="icon fa-caret-right"></span>{" "}
            <Link to="/updates">Updates</Link>{" "}
            <span className="icon fa-caret-right"></span> {title}
            <br />
            <br />
            <header className={`header major`}>
              <h1>{title}</h1>
            </header>
            <p>{moment(date).format("MMMM Do, YYYY")}</p>
            <span className="image main">
              {/* <Img fluid={data.markdownRemark.image.childImageSharp.fluid} /> */}
              {/* <img src={data.markdownRemark.frontmatter.featuredimage} /> */}
            </span>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default Post;

export const query = graphql`
  query PostTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        featuredimage {
          relativePath
        }
        title
      }
      html
      excerpt(pruneLength: 300)
    }
  }
`;
