import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BannerUpdates from "../components/BannerUpdates";
import moment from "moment";
import { getSlug } from "../utils/getSlug";
import Img from "gatsby-image";
import { Index } from "elasticlunr";

const Updates = props => {
  const data = useStaticQuery(graphql`
    query UpdatesQuery {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { templateKey: { ne: "page" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              date
            }
            html
            id
            excerpt(pruneLength: 400)
            rawMarkdownBody
          }
        }
      }
      siteSearchIndex {
        index
      }
    }
  `);

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const search = evt => {
    const query = evt.target.value;
    const index = Index.load(data.siteSearchIndex.index);
    setQuery(query);
    setResults(
      index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => index.documentStore.getDoc(ref))
    );
  };

  return (
    <Layout>
      <SEO
        title="Updates"
        description={`Latest VDH7 Updates. Stay tuned for the latest info!`}
      />

      <BannerUpdates title="Updates" />
      <div className="updates">
        <div id="main">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h2>VDH7 Updates</h2>
              </header>
              <p>
                Updates for VDH7 will be posted here. Stay tuned for the latest
                info!
              </p>

              <div className="grid-wrapper">
                <div className="col-12 ">
                  <h5>Looking for something? Search Updates:</h5>
                  <div className="searchInput">
                    <input
                      type="text"
                      name="search"
                      id="search"
                      label="Search"
                      placeholder="Search Updates"
                      value={query}
                      onChange={search}
                    />
                  </div>
                </div>
              </div>
              {query.length > 0 && (
                <div>
                  <div className="mb-5">
                    <button
                      className="small"
                      onClick={() => {
                        setQuery("");
                        setResults([]);
                      }}
                    >
                      Clear
                    </button>
                  </div>
                  <h4>Results: {results.length}</h4>
                </div>
              )}
              {results.length > 0 && (
                <div>
                  {results.map(result => (
                    <div key={result.id} className="searchResult">
                      <span className="searchDate">
                        {moment(result.date).format("MMM D, YYYY")}
                      </span>

                      <Link to={`/${getSlug(result.title)}`}>
                        {result.title}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
          <section id="two" className="spotlights">
            {data.allMarkdownRemark.edges.map(
              ({
                node: {
                  frontmatter: { title, date, featuredimage },
                  id,
                  excerpt,
                },
              }) => (
                <section key={id} className="updateSection">
                  <Link to={`/${getSlug(title)}`} className="image">
                    <Img
                      fluid={featuredimage.childImageSharp.fluid}
                      alt={title}
                    />
                  </Link>
                  <div className="content">
                    <div className="inner">
                      <header className="header major">
                        <Link to={`/${getSlug(title)}`}>
                          <h3>{title}</h3>
                        </Link>
                        <p>{moment(date).format("MMMM Do, YYYY")}</p>
                      </header>

                      <div className="indexArticle">
                        <p>{excerpt}</p>
                      </div>
                      <ul className="actions">
                        <li>
                          <Link to={`/${getSlug(title)}`} className="button">
                            View Post
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              )
            )}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Updates;
