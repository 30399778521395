import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import moment from "moment";
import LoadableTableau from "../components/Tableau/LoadableTableau";
import BannerRegistration from "../components/BannerRegistration";
import SEO from "../components/seo";

// import flyer from "../images/Flyer.png";

const Registration = props => {
  const {
    markdownRemark: {
      html,
      frontmatter: { buttontext, registerlink, table, title },
    },
  } = useStaticQuery(graphql`
    query RegistrationQuery {
      markdownRemark(frontmatter: { pageName: { eq: "registration" } }) {
        frontmatter {
          buttontext
          registerlink
          table {
            deadlineearly
            deadlinenormal
            deadlinelate
            feeearly
            feenormal
            feelate
          }
          title
        }
        html
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title="Registration"
        description={`Registration Eligibility and Information`}
      />

      <BannerRegistration
        title={title}
        // heading={data.markdownRemark.frontmatter.heading}
      />

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            {/* <header className="major">
              <h1>{data.markdownRemark.frontmatter.title}</h1>
            </header> */}
            {/* <span className="image main">
            <img src={flyer} alt="" />
          </span> */}

            <div className="table-wrapper">
              <table className="alt">
                <thead>
                  <tr>
                    <th>Deadline</th>
                    <th>Type</th>
                    <th>Registration Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>{moment(table.deadlineearly).format("MM/DD/YYYY")}</td>
                    <td>Early Bird Registration</td>
                    <td>{`$${table.feeearly}`}</td>
                  </tr> */}
                  <tr>
                    <td>{moment(table.deadlinenormal).format("MM/DD/YYYY")}</td>

                    <td>Normal Registration</td>
                    <td>{`$${table.feenormal}`}</td>
                  </tr>
                  <tr>
                    <td>{moment(table.deadlinelate).format("MM/DD/YYYY")}</td>

                    <td>Late Registration</td>
                    <td>{`$${table.feelate}`}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="1"></td>
                    {/* <td>Absolute Deadline to Register: 5/15/2021</td> */}
                  </tr>
                </tfoot>
              </table>
              <ul className="actions">
                <li>
                  <a
                    href={registerlink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button special big disabled"
                  >
                    {buttontext}
                  </a>
                </li>
              </ul>
            </div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <br />
            <hr />
            <br />
            <h3>Registration Statistics</h3>
            <br />
            <LoadableTableau />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Registration;
